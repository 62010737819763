import { stringify } from './querystring';

const TOKEN_KEY = `smarternak_access_token`;
const TOKEN_EXPIRATION_KEY = `smarternak_expiration_date`;
const CLIENT_ID =
  process.env.NODE_ENV === `production`
    ? `49d10789-62b9-4d81-900f-c897dc050332`
    : `testclientid`;

const REDIRECTION_URI =
  process.env.NODE_ENV === `production`
    ? `https://app.smarternak.com/callback`
    : `http://localhost:3000/callback`;

const BASE_URL =
  process.env.NODE_ENV === `production`
    ? `https://app.smarternak.com`
    : `https://app.smarternak.com`;
      //: `http://localhost:8090`;

export function authorize() {
  const query = {
    client_id: CLIENT_ID,
    response_type: `token`,
    redirect_uri: REDIRECTION_URI,
  };

  const url = `${BASE_URL}/oauth2/authorize?${stringify(query)}`;

  window.location.href = url;
}

export function logOut() {
  clearToken();
  clearExpirationDate();

  window.location.href = `/`;
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function clearToken() {
  localStorage.removeItem(TOKEN_KEY);
}

function getParameterByName(name) {
  let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function setToken() {
  let accessToken = getParameterByName(`access_token`);
  localStorage.setItem(TOKEN_KEY, accessToken);
}

export function setExpirationDate() {
  const expireInSeconds = parseInt(getParameterByName('expires_in'), 10);
  const date = new Date(Date.now() + expireInSeconds * 1000);

  localStorage.setItem(TOKEN_EXPIRATION_KEY, date.toISOString());
}

export function getExpirationDate() {
  let date = localStorage.getItem(TOKEN_EXPIRATION_KEY);
  if (date) {
    return new Date(date);
  }

  return new Date();
}

export function isTokenExpired() {
  return new Date() > getExpirationDate();
}

export function clearExpirationDate() {
  localStorage.removeItem(TOKEN_EXPIRATION_KEY);
}

export function isLoggedIn() {
  return getToken() && !isTokenExpired();
}
