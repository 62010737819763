import { getHistoricalDataOf } from '../utils/historical_sensor_data';

function sortByDate(a, b) {
  const aTime = new Date(a.timestamp);
  const bTime = new Date(b.timestamp);

  return aTime - bTime;
}

const blacklisted = [
  `state`,
  `motion_prediction`,
  `battery_info`,
  `step_counter`,
  `motion_type`,
];

const model = {
  namespace: `historical_data`,
  state: {
    open: false,
    data: [],
    id: ``,
    sensor: ``,
    sensorKey: ``,
    metadata: {},
  },

  reducers: {
    openDialog(state, { key, label, id }) {
      if (blacklisted.includes(key)) {
        return state;
      }

      return { ...state, open: true, sensor: label, sensorKey: key, id };
    },

    closeDialog(state) {
      return { ...state, open: false };
    },

    fetchedData(state, action) {
      const { data } = action;

      return {
        ...state,
        data: data.sort(sortByDate),
        metadata: {
          fetched_count: action.fetched_count,
          total_count: action.total_count,
          limit: action.limit,
          offset: action.offset,
        },
      };
    },
  },

  effects: {
    *openDialog({ key, id }, { put }) {
      if (blacklisted.includes(key)) {
        return false;
      }

      yield put({ type: `fetchData`, key, id, time: `1day` });
    },

    *fetchData({ key, id, time }, { call, put }) {
      const result = yield call(getHistoricalDataOf, id, key, time);
      yield put({ type: `fetchedData`, ...result });
    },

    *setTime({ time }, { put, select }) {
      const { id, key } = yield select((state) => ({
        id: state.historical_data.id,
        key: state.historical_data.sensorKey,
      }));
      yield put({ type: `fetchData`, key, id, time });
    },
  },
};

export default model;
