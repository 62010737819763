import { getWaterLevelSensors } from '../utils/water_level_sensors_api';

const model = {
  namespace: `water_level_sensors`,
  state: {
    sensors: [],
  },

  reducers: {
    loadedData(state, action) {
      return {
        ...state,
        sensors: action.result,
      };
    },
  },

  effects: {
    *load(action, { call, put }) {
      try {
        const result = yield call(getWaterLevelSensors);
        yield put({ type: `loadedData`, result });
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};

export default model;
