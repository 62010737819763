import { getVisibilityStatus, getPairedCows } from '../utils/cows_api';
import { getAreas } from '../utils/area_api';

const model = {
  namespace: `tracking`,

  state: {
    devices: [],
    map_center: {
      lat: -0.471307,
      lng: 113.953698,
    },
    map_zoom: 4,
    map_style: {
      height: `90vh`,
      width: `100%`,
    },
    objects: [],
    objects_metadata: {},
    objects_visibility: {
      seen: 0,
      unseen: 0,
      total: 0,
    },
    boundaries: [],
    boundaries_metadata: {},
    show_data_filter: `all`,
    highlight_filter: `unseen`,
  },

  reducers: {
    // pan map to a device location
    mapPanTo(state, action) {
      for (let i = 0; i < state.devices.length; i++) {
        const currentDevice = state.devices[i];
        if (currentDevice.id === action.id) {
          let lat, lng;
          if (typeof currentDevice.latitude === `undefined`) {
            lat = 0;
          } else {
            lat = currentDevice.latitude;
          }

          if (typeof currentDevice.longitude === `undefined`) {
            lng = 0;
          } else {
            lng = currentDevice.longitude;
          }

          return {
            ...state,
            map_center: {
              lat,
              lng,
            },
          };
        }
      }

      return state;
    },

    fetchedObjectList(state, action) {
      const objectResponse = action.result;
      return {
        ...state,
        objects: objectResponse.data.map((object) => {
          const newObject = { ...object };

          if (newObject.tracking_info && newObject.tracking_info.lastSeen) {
            newObject.tracking_info.lastSeen = new Date(
              newObject.tracking_info.lastSeen
            );
          }

          return newObject;
        }),
        objects_metadata: {
          offset: objectResponse.offset,
          limit: objectResponse.limit,
          total_count: objectResponse.total_count,
          fetched_count: objectResponse.fetched_count,
        },
      };
    },

    fetchedVisibilityStatus(state, action) {
      return {
        ...state,
        objects_visibility: action.result,
      };
    },

    fetchedAreaData(state, action) {
      const boundaryResponse = action.result;
      return {
        ...state,
        boundaries: boundaryResponse.data,
        boundaries_metadata: {
          offset: boundaryResponse.offset,
          limit: boundaryResponse.limit,
          total_count: boundaryResponse.total_count,
          fetched_count: boundaryResponse.fetched_count,
        },
      };
    },

    newTelemetryData(state, action) {
      const telemetryData = action.data;
      let foundObjectData = false;
      const duplicateObjects = [...state.objects];

      for (let i = 0; i < duplicateObjects.length; i += 1) {
        const current = duplicateObjects[i];
        if (current._id === telemetryData.cow_object_id) {
          foundObjectData = true;

          const { data } = telemetryData;
          if (
            typeof data.latitude !== `undefined` &&
            typeof data.longitude !== `undefined`
          ) {
            if (data.latitude !== 0 && data.longitude !== 0) {
              current.tracking_info = {
                latitude:
                  data.latitude === 0
                    ? current.tracking_info.latitude
                    : data.latitude,
                longitude:
                  data.longitude === 0
                    ? current.tracking_info.longitude
                    : data.longitude,
              };
            }
          }

          current.tracking_info.lastSeen = new Date();

          if (
            typeof data.last_alert_number !== `undefined` &&
            data.last_alert_number > -1
          ) {
            current.device_info.last_alert_number = data.last_alert_number;
            current.device_info.last_alert_type = data.last_alert_type;
            current.device_info.last_alert_datetime = data.last_alert_datetime;
          }

          if (typeof data.alert !== `undefined`) {
            current.alert = {
              ...data.alert,
            };

            delete data.alert;
          }
        }
      }

      if (foundObjectData) {
        duplicateObjects.sort((a, b) => {
          return a.tracking_info.lastSeen < b.tracking_info.lastSeen;
        });

        return {
          ...state,
          objects: duplicateObjects,
        };
      }

      return state;
    },

    trackingFilterValueChange(state, action) {
      return {
        ...state,
        [action.key]: action.value,
      };
    },

    trackingFilterApply(state, action) {
      return {
        ...state,
        show_data_filter: action.filter.show,
        highlight_filter: action.filter.highlight,
      };
    },

    setCenterAndZoom(state, { center, zoom }) {
      return {
        ...state,
        map_center: center,
        map_zoom: zoom,
      };
    },
  },

  effects: {
    *getObjects(action, { call, put }) {
      try {
        const result = yield call(getPairedCows, action.query);
        yield put({ type: `fetchedObjectList`, result });
      } catch (ex) {
        console.error(ex);
      }
    },

    *getVisibilityData(action, { call, put }) {
      try {
        const result = yield call(getVisibilityStatus);
        yield put({ type: `fetchedVisibilityStatus`, result });
      } catch (ex) {
        console.error(ex);
      }
    },

    *getAreaData(action, { call, put }) {
      try {
        const result = yield call(getAreas);
        yield put({ type: `fetchedAreaData`, result });

        const { data } = result;
        const defaults = data.filter((datum) => datum.is_default);
        if (defaults.length > 0) {
          const [defaultArea] = defaults;
          const center = {
            lat: defaultArea.center_latitude,
            lng: defaultArea.center_longitude,
          };
          const zoom = 18;

          yield put({ type: `setCenterAndZoom`, center, zoom });
        }
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};

export default model;
