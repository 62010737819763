import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';

export function getDashboardData() {
  const url = `${BASE_URL}/api/v1/dashboard/web`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}
