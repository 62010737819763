import { wsInstance } from '../websocket';
import { fromWebsocketData } from '../utils/transformer';

const model = {
  namespace: `realtime_tracking`,
  state: {},
  reducers: {},

  effects: {},

  subscriptions: {
    wsData({ dispatch }) {
      console.log(`listening for websocket data`);

      wsInstance.onEvent(`device_data`, (data) => {
        const preprocessedData = fromWebsocketData(data);

        dispatch({ type: `tracking/newTelemetryData`, data });
        dispatch({
          type: `detail_cow/newTelemetryData`,
          preprocessedData,
          data,
        });
        dispatch({
          type: `predicted_activity_stats/newTelemetryData`,
          preprocessedData,
          data,
        });
      });

      wsInstance.onEvent(`cattle_events`, (data) => {
        let parsed = {};
        try {
          parsed = JSON.parse(data);

          dispatch({ type: `detail_cow/realtimeCattleEvent`, data: parsed });
          dispatch({ type: `dashboard/realtimeCattleEvent`, data: parsed });
        } catch (ex) {}
      });
    },
  },
};

export default model;
