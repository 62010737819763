import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';

export function getCowsEventList(id) {
  const url = `${BASE_URL}/api/v1/cows/${id}/events`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}
