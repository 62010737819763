import { getUsers } from '../utils/users_api';

const model = {
  namespace: `users`,
  state: {
    data: [],
    metadata: {
      offset: 0,
      limit: 20,
      total_count: 0,
    },
  },

  reducers: {
    fetched(state, action) {
      return {
        ...state,
        data: action.result.data.map((datum) => {
          const newData = { ...datum };
          newData.registered_date = new Date(
            newData.registered_date
          ).toLocaleDateString();

          return newData;
        }),
        metadata: {
          offset: action.result.offset,
          limit: action.result.limit,
          total_count: action.result.total_count,
        },
      };
    },
  },

  effects: {
    *fetch(action, { call, put }) {
      try {
        const result = yield call(getUsers, action.query);
        yield put({ type: `fetched`, result });
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};

export default model;
