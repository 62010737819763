import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'dva';
import makeStyles from '@material-ui/core/styles/makeStyles';

const style = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    textAlign: `center`,
  },

  back: {
    '&:hover': {
      cursor: `pointer`,
    },
    textDecoration: `underline`,
  },
});

const useStyles = makeStyles(style);

function NotFoundPage() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div id="not-found-page" className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Oops!
      </Typography>
      <Typography variant="subtitle1" paragraph>
        The page that you are looking for is nowhere to be found!{' '}
        <span className={classes.back} onClick={() => history.goBack()}>
          Go back
        </span>
        , perhaps?
      </Typography>
    </div>
  );
}

export default NotFoundPage;
