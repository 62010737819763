export function assignIfAvailable(dest, destProp, source, srcProp) {
  if (typeof source[srcProp] !== `undefined`) {
    dest[destProp] = source[srcProp];
  }
}

export function parseJSON(jsonData) {
  let parsed = {};
  try {
    parsed = JSON.parse(jsonData);
  } catch (ex) {}

  return parsed;
}

export function getCowDataFromWebsocket(jsonData) {
  const parsed = parseJSON(jsonData);
  if (parsed.cow_object_id) {
    return {
      cow_object_id: parsed.cow_object_id,
    };
  }

  return {};
}

export function fromWebsocketData(jsonData) {
  let parsedData = parseJSON(jsonData);

  const { data } = parsedData;
  if (!data) {
    return {};
  }

  const newData = {};
  newData.id = parsedData.id;
  newData.cow_object_id = parsedData.cow_object_id;
  assignIfAvailable(newData, 'battery_voltage', data, 'batteryVoltage');
  assignIfAvailable(newData, 'battery_capacity', data, 'batteryCapacity');
  assignIfAvailable(newData, 'battery_charge', data, 'batteryCharge');
  assignIfAvailable(newData, 'battery_current', data, 'batteryCurrent');
  assignIfAvailable(newData, 'location_latitude', data, 'latitude');
  assignIfAvailable(newData, 'location_longitude', data, 'longitude');
  assignIfAvailable(newData, 'location_altitude', data, 'altitude');
  assignIfAvailable(newData, 'location_displacement', data, 'displacement');
  assignIfAvailable(newData, 'location_heading', data, 'heading');
  assignIfAvailable(newData, 'location_speed', data, 'speed');
  assignIfAvailable(newData, 'object_temperature', data, 'temperature');
  assignIfAvailable(
    newData,
    'object_body_temperature',
    data,
    'bodyTemperature'
  );
  assignIfAvailable(
    newData,
    'object_ambient_temperature',
    data,
    'ambientTemperature'
  );
  assignIfAvailable(newData, 'state', data, 'state');
  assignIfAvailable(newData, 'env_luminosity', data, 'luminosity');
  assignIfAvailable(
    newData,
    'env_barometric_pressure',
    data,
    'barometricPressure'
  );
  assignIfAvailable(newData, 'fsr_value', data, 'fsr');
  assignIfAvailable(newData, 'step_counter', data, 'stepCount');
  assignIfAvailable(newData, 'gw_rssi', data, 'gwRssi');
  assignIfAvailable(newData, 'last_alert_type', data, 'last_alert_type');
  assignIfAvailable(newData, 'last_alert_number', data, 'last_alert_number');
  assignIfAvailable(
    newData,
    'last_alert_datetime',
    data,
    'last_alert_datetime'
  );
  assignIfAvailable(newData, 'motion_type', data, 'motion');
  assignIfAvailable(newData, 'motion_prediction', data, 'motion_prediction');
  assignIfAvailable(newData, 'alert', data, 'alert');
  assignIfAvailable(
    newData,
    'calculated_body_weight',
    data,
    'calculated_body_weight'
  );
  assignIfAvailable(
    newData,
    'calculated_body_length',
    data,
    'calculated_body_length'
  );
  assignIfAvailable(newData, 'events', data, 'events');
  assignIfAvailable(newData, 'detached', data, 'detached');

  newData.last_active = new Date();

  return newData;
}

export function getMotionStringFromMotionInt(motionInt) {
  switch (motionInt) {
    case 0:
      return `idle`;
    case 9:
      return `significant motion`;
    case 10:
      return `free fall`;
    case 11:
      return `step`;
    case 12:
      return `tilt`;
    case 14:
      return `wake up`;
    case 100:
      return `moved`;
    default:
      return `unknown`;
  }
}
