import { getOneUser } from '../utils/users_api';

const model = {
  namespace: `user_data`,
  state: {
    data: {
      profile: {},
    },
    id: ``,
  },

  reducers: {
    load(state, action) {
      return {
        ...state,
        id: action.id,
      };
    },

    loaded(state, action) {
      return {
        ...state,
        data: {
          ...action.result,
        },
      };
    },
  },

  effects: {
    *load({ id }, { call, put }) {
      try {
        const result = yield call(getOneUser, id);
        yield put({ type: `loaded`, result });
      } catch (ex) {
        yield put({
          type: `app/showSnackbar`,
          message: `Failed loading user's profile`,
        });
      }
    },
  },
};

export default model;
