import { useEffect } from 'react';
import { setExpirationDate, setToken } from '../utils/auth';
import { useHistory } from 'dva';

function OAuth2Callback() {
  const history = useHistory();

  useEffect(() => {
    setToken();
    setExpirationDate();

    history.push(`/`);
  }, [history]);

  return null;
}

export default OAuth2Callback;
