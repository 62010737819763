import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';

export function getTodoList() {
  const url = `${BASE_URL}/api/v1/todos`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function setDone(id) {
  const url = `${BASE_URL}/api/v1/todos/${id}/done`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `PATCH`,
  };

  return getAndParseData(url, options);
}
