import { useEffect } from 'react';
import { logOut } from '../utils/auth';

function SignOutPage() {
  useEffect(() => {
    logOut();
  }, []);

  return null;
}

export default SignOutPage;
