import { getAvailableFodderCount } from '../utils/fodder_api';

const model = {
  namespace: `fodder`,
  state: {
    availableCount: 0,
  },

  reducers: {
    loadedAvailableCount(state, action) {
      return {
        ...state,
        availableCount: action.result.count,
      };
    },
  },

  effects: {
    *loadAvailableCount(action, { call, put }) {
      try {
        const result = yield call(getAvailableFodderCount);
        yield put({ type: `loadedAvailableCount`, result });
      } catch (ex) {
        console.error(ex);
        yield put({
          type: `app/showSnackbar`,
          message: `Failed fetching fodder. Try again later.`,
        });
      }
    },
  },
};

export default model;
