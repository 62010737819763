import { getDashboardData } from '../utils/dashboard_api';
import dashboardDefaults from './defaults/dashboard';
import { getTodoList, setDone } from '../utils/todo_api';

import heartIconBorderedIcon from '../images/heart_icon_bordered.png';
import cowFaceGreenIcon from '../images/cow_face_green.png';
import weightIcon from '../images/weight.png';
import activeIcon from '../images/active_icon.png';
import sickIcon from '../images/ambient_temperature.png';
import lameIcon from '../images/lame_icon.png';
import unseenIcon from '../images/unseen_icon.png';
import watchlistIcon from '../images/watch_list_icon.png';

const labels = {
  lame: `Lame`,
  sick: `Sick`,
  unseen: `Unseen`,
  watch_list: `Watch List`,
  in_heat: `In Heat`,
  calving: `Calving`,
  weight_gain: `Weight Gain`,
  active: `Active`,
};

const icons = {
  in_heat: heartIconBorderedIcon,
  calving: cowFaceGreenIcon,
  weight_gain: weightIcon,
  active: activeIcon,
  lame: lameIcon,
  sick: sickIcon,
  unseen: unseenIcon,
  watch_list: watchlistIcon,
};

const model = {
  namespace: `dashboard`,
  state: {
    attentions: dashboardDefaults.attentions,
    performance: dashboardDefaults.performance,
    events: [],
    todos: [],
    owner_id: ``,
  },

  reducers: {
    attentionsLoaded(state, { attentions }) {
      const skippedSick = attentions.map((attention) => {
        if (attention.label.toLowerCase() === `sick`) {
          return { ...attention, value: `0` };
        }

        return attention;
      });

      return {
        ...state,
        attentions: skippedSick,
      };
    },

    performanceLoaded(state, { performance }) {
      return {
        ...state,
        performance,
      };
    },

    eventsLoaded(state, { events }) {
      return {
        ...state,
        events,
      };
    },

    todoListLoaded(state, { todos }) {
      return {
        ...state,
        todos,
      };
    },

    fetchedOwnerData(state, { result }) {
      return {
        ...state,
        owner_id: result._id,
      };
    },

    realtimeCattleEvent(state, action) {
      if (action.data.scope && state.owner_id) {
        if (action.data.scope !== state.owner_id) {
          return state;
        }
      }

      const newItem = [action.data];
      const oldEventArray = state.events.slice();
      oldEventArray.pop();

      const newEvents = [].concat(newItem, oldEventArray);

      return {
        ...state,
        events: newEvents,
      };
    },
  },

  effects: {
    *fetchDashboardData(action, { call, put }) {
      const data = yield call(getDashboardData);

      // get attentions
      const attentionKeys = Object.keys(data.attentions);
      const attentions = attentionKeys.map((key) => {
        const link = `/tracking?filter=1&highlight=${key}&show=all`;
        const label = labels[key];

        return {
          label,
          value: `${data.attentions[key]}`,
          linkTo: link,
          icon: icons[key],
        };
      });

      yield put({ type: `attentionsLoaded`, attentions });

      // get performance
      const performanceKeys = Object.keys(data.performance).filter(
        (key) => !['in_heat', 'calving'].includes(key)
      );
      const performance = performanceKeys.map((key) => {
        const link = `/tracking?filter=1&highlight=${key}&show=all`;
        const label = labels[key];

        return {
          label,
          value: `${data.performance[key]}`,
          linkTo: link,
          icon: icons[key],
        };
      });

      yield put({ type: `performanceLoaded`, performance });

      const events = data.event.list;
      yield put({ type: `eventsLoaded`, events });

      const todos = data.todo.list.map((todo) => {
        const newResult = {
          ...todo,
          id: todo._id,
        };

        delete newResult._id;

        return newResult;
      });

      yield put({ type: `todoListLoaded`, todos });
    },

    *reloadTodoList(action, { call, put }) {
      const data = yield call(getTodoList);
      if (data.list) {
        const todos = data.list.map((todo) => {
          const newResult = {
            ...todo,
            id: todo._id,
          };

          delete newResult._id;

          return newResult;
        });

        yield put({ type: `todoListLoaded`, todos });
      }
    },

    *markTodoListAsDone({ id }, { call, put }) {
      try {
        yield call(setDone, id);
        yield put({ type: `reloadTodoList` });
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};

export default model;
