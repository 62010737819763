import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';
import { stringify } from './querystring';

export function getRecommendationsByCowID(cowID, query = {}) {
  const url = `${BASE_URL}/api/v1/cows/${cowID}/recommendations?${stringify(
    query
  )}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}
