import React from 'react';
import { Link } from 'dva/router';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const style = () => ({
  link: {
    textDecoration: `none`,
    color: `inherit`,
  },
});

const useStyles = makeStyles(style);

function MainMenuList(props) {
  const classes = useStyles();
  const { menus } = props;

  return (
    <div>
      <List>
        {menus.map((menu, idx) => (
          <Link to={menu.linkTo} className={classes.link} key={idx}>
            <ListItem button>
              <Tooltip title={menu.text} placement="top">
                <ListItemIcon>{menu.icon}</ListItemIcon>
              </Tooltip>
              <ListItemText primary={menu.text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
}

MainMenuList.propTypes = {
  classes: PropTypes.object,
  menus: PropTypes.arrayOf(PropTypes.object),
};

MainMenuList.defaultProps = {
  menus: [],
  classes: {},
};

export default MainMenuList;
