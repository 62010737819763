import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';
import { stringify } from './querystring';

export function getAreas(query = {}) {
  let url = `${BASE_URL}/api/v1/areas`;
  const headers = withToken({
    ...defaultHeaders,
  });

  if (Object.keys(query).length > 1) {
    url += `?${stringify(query)}`;
  }

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function createArea(payload) {
  const url = `${BASE_URL}/api/v1/areas`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `POST`,
    body: JSON.stringify(payload),
  };

  return getAndParseData(url, options);
}

export function setAsDefaultArea(id) {
  const url = `${BASE_URL}/api/v1/areas/${id}/default`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `PUT`,
  };

  return getAndParseData(url, options);
}
