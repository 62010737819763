import { getRecommendationsByCowID } from '../utils/recommendations_api';

const model = {
  namespace: `cows_recommendation`,
  state: {
    recommendations: [],
  },

  reducers: {
    loaded(state, action) {
      return {
        ...state,
        recommendations: action.result.data,
      };
    },
  },

  effects: {
    *load({ cowID }, { call, put }) {
      try {
        const result = yield call(getRecommendationsByCowID, cowID);
        yield put({ type: `loaded`, result });
      } catch (ex) {
        console.error(ex);
        yield put({
          type: `app/showSnackbar`,
          message: `Failed loading recommendations. Try again later`,
        });
      }
    },
  },
};

export default model;
