import { getOwnerDetail } from '../utils/owner_api';

const model = {
  namespace: `app`,
  state: {
    snackbar_open: false,
    snackbar_message: ``,
    owner: {
      _id: ``,
      name: ``,
    },
  },

  reducers: {
    hideSnackbar(state) {
      return {
        ...state,
        snackbar_open: false,
        snackbar_message: ``,
      };
    },

    showSnackbar(state, { message }) {
      return {
        ...state,
        snackbar_open: true,
        snackbar_message: message,
      };
    },

    fetchedOwnerData(state, action) {
      const { result } = action;
      return {
        ...state,
        owner: result,
      };
    },
  },

  effects: {
    *getOwnerData(action, { call, put }) {
      try {
        const result = yield call(getOwnerDetail);
        yield put({ type: `fetchedOwnerData`, result });
        yield put({ type: `dashboard/fetchedOwnerData`, result });
      } catch (ex) {
        yield put({ type: `showSnackbar`, message: ex.message });
      }
    },
  },
};

export default model;
