import { fromWebsocketData, assignIfAvailable } from '../utils/transformer';
import {
  getDetailCow,
  getAreaOfCow,
  setAreaOfCow,
  getHistoryMapOfCow,
} from '../utils/cows_api';
import { getCowsEventList } from '../utils/events_api';
import { getAcvitiyDuration } from '../utils/predicted_activity_api';

const model = {
  namespace: `detail_cow`,
  state: {
    detail: {},
    area: {
      polygon: [],
      polygon_geo: {
        type: `Polygon`,
        coordinates: [[]],
      },
      name: ``,
    },
    events: [],
    history_markers: [],
    history_markers_time: `live`,
    activity_durations: [],
    activity_duration_time: `1hour`,
  },
  reducers: {
    fetchedDetailData(state, action) {
      return {
        ...state,
        detail: action.result,
      };
    },

    fetchedAreaData(state, action) {
      return {
        ...state,
        area: action.result,
      };
    },

    fetchedDetailEvents(state, action) {
      const events = action.result.data;
      return {
        ...state,
        events,
      };
    },

    realtimeCattleEvent(state, action) {
      if (action.data.subject && state.detail) {
        if (action.data.subject._id !== state.detail._id) {
          return state;
        }
      }

      const newItem = [action.data];
      const oldEventArray = state.events.slice();
      oldEventArray.pop();

      const newEvents = [].concat(newItem, oldEventArray);

      return {
        ...state,
        events: newEvents,
      };
    },

    newTelemetryData(state, action) {
      const telemetryData = action.preprocessedData
        ? { ...action.preprocessedData }
        : fromWebsocketData(action.data);

      // probably detail is not loaded yet
      if (Object.keys(state.detail).length < 1 || !state.detail.device_info) {
        return state;
      }

      // update cow display data.
      if (typeof telemetryData.calculated_body_weight !== `undefined`) {
        telemetryData.calculated_body_weight = parseFloat(
          telemetryData.calculated_body_weight.toFixed(2)
        );
      }

      if (typeof telemetryData.calculated_body_length !== `undefined`) {
        telemetryData.calculated_body_length = parseFloat(
          telemetryData.calculated_body_length.toFixed(2)
        );
      }

      let history_markers = [...state.history_markers];
      if (typeof telemetryData.location_latitude !== `undefined`) {
        const liveMarker = {};
        liveMarker.position = {
          lat: telemetryData.location_latitude,
          lng: telemetryData.location_longitude,
        };
        liveMarker.data = {};
        assignIfAvailable(
          liveMarker.data,
          `speed`,
          telemetryData,
          `location_speed`
        );
        assignIfAvailable(
          liveMarker.data,
          `altitude`,
          telemetryData,
          `location_altitude`
        );
        assignIfAvailable(
          liveMarker.data,
          `displacement`,
          telemetryData,
          `location_displacement`
        );
        assignIfAvailable(
          liveMarker.data,
          `heading`,
          telemetryData,
          `location_heading`
        );

        history_markers = [liveMarker, ...history_markers.slice(1)];
      }

      if (telemetryData.id === state.detail.device_info.id) {
        return {
          ...state,
          history_markers,
          detail: {
            ...state.detail,
            device_info: {
              ...state.detail.device_info,
              ...telemetryData,
            },
            alert: { ...telemetryData.alert },
          },
        };
      }

      return {
        ...state,
      };
    },

    getLiveMarkers(state, action) {
      const { detail } = state;
      if (!detail.device_info) {
        return state;
      }

      const deviceInfo = detail.device_info;
      const marker = {};
      marker.position = {
        lat: deviceInfo.location_latitude,
        lng: deviceInfo.location_longitude,
      };
      marker.data = {};
      assignIfAvailable(
        marker.data,
        `altitude`,
        deviceInfo,
        `location_altitude`
      );
      assignIfAvailable(marker.data, `speed`, deviceInfo, `location_speed`);
      assignIfAvailable(marker.data, `heading`, deviceInfo, `location_heading`);
      assignIfAvailable(
        marker.data,
        `displacement`,
        deviceInfo,
        `location_displacement`
      );

      return {
        ...state,
        history_markers: [marker],
        history_markers_time: `live`,
      };
    },

    fetchedHistoryMap(state, action) {
      return {
        ...state,
        history_markers: action.data,
        history_markers_time: action.time,
      };
    },

    fetchedActivityDurations(state, action) {
      return {
        ...state,
        activity_durations: action.data.durations.map((datum) => {
          const newDatum = {
            ...datum,
            start: new Date(datum.start),
            end: new Date(datum.end),
          };

          return newDatum;
        }),
        activity_duration_time: action.time,
      };
    },
  },

  effects: {
    *fetchDetailCow(action, { call, put }) {
      const { id } = action;
      try {
        const result = yield call(getDetailCow, id);
        yield put({ type: `fetchedDetailData`, result });
        yield put({ type: `getLiveMarkers` });
      } catch (ex) {
        console.error(ex);
      }
    },

    *fetchDetailArea(action, { call, put }) {
      const { id } = action;
      try {
        const result = yield call(getAreaOfCow, id);

        yield put({ type: `fetchedAreaData`, result });
      } catch (ex) {
        console.error(ex);
      }
    },

    *fetchDetailEvents(action, { call, put }) {
      const { id } = action;

      try {
        const result = yield call(getCowsEventList, id);
        yield put({ type: `fetchedDetailEvents`, result });
      } catch (ex) {
        console.error(ex);
      }
    },

    *setCowAreaId(action, { call, put, select }) {
      const { areaId } = action;

      try {
        const cowId = yield select((state) => state.detail_cow.detail._id);
        yield call(setAreaOfCow, cowId, areaId);
        yield put({ type: `fetchDetailArea`, id: cowId });
        yield put({ type: `fetchDetailCow`, id: cowId });
      } catch (ex) {
        console.error(ex);
      }
    },

    *fetchHistoryMap(action, { call, put }) {
      const { id, timeFrame } = action;

      try {
        const result = yield call(getHistoryMapOfCow, id, timeFrame);
        yield put({ type: `fetchedHistoryMap`, time: timeFrame, data: result });
      } catch (ex) {
        console.error(ex);
      }
    },

    *fetchActivityDurations(action, { call, put }) {
      const { id, timeFrame, labels = [] } = action;

      try {
        const result = yield call(
          getAcvitiyDuration,
          id,
          timeFrame,
          '',
          labels.join(',')
        );
        yield put({
          type: `fetchedActivityDurations`,
          time: timeFrame,
          data: result,
        });
      } catch (ex) {
        console.error(ex);
      }
    },
  },

  subscriptions: {},
};

export default model;
