import { useEffect } from 'react';
import { authorize } from '../utils/auth';

function OAuth2Login() {
  useEffect(() => {
    authorize();
  }, []);

  return null;
}

export default OAuth2Login;
