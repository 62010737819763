import socket from 'socket.io-client';

class WebSocket {
  constructor() {
    const url =
      process.env.NODE_ENV === `production`
        ? `wss://app.smarternak.com:9090`
        : `wss://app.smarternak.com:9090`;
          //: `ws://localhost:9090`;
    const options = {
      reconnect: true,
      secure: true,
      rejectUnauthorized: false,
      forceNew: true,
      transports: [`polling`, `websocket`],
    };
    this.io = socket(url, options);
    this.io.on(`connect`, () => {
      console.log(`WS connected`);
    });
  }

  onEvent = (event, handler) => {
    this.io.on(event, handler);
  };

  offEvent = (event) => {
    this.io.off(event);
  };

  emit = (event, data) => {
    this.io.emit(event, data);
  };
}

export const wsInstance = new WebSocket();

export default WebSocket;
