import { getAreas, setAsDefaultArea } from '../utils/area_api';

const model = {
  namespace: `list_area`,
  state: {
    areas: [],
    areas_metadata: {
      total_count: 0,
    },
  },

  reducers: {
    loadedAreas(state, action) {
      const loadAreaResponse = action.result;
      return {
        ...state,
        areas: loadAreaResponse.data.map((area) => {
          const newArea = { ...area };
          newArea.created_date = new Date(area.created_date).toLocaleString();

          return newArea;
        }),
        areas_metadata: {
          offset: loadAreaResponse.offset,
          limit: loadAreaResponse.limit,
          total_count: loadAreaResponse.total_count,
          fetched_count: loadAreaResponse.fetched_count,
        },
      };
    },
  },

  effects: {
    *loadAreas({ query }, { call, put }) {
      const result = yield call(getAreas, query);
      yield put({ type: `loadedAreas`, result });
    },

    *setAreaAsDefault({ area }, { call, put }) {
      if (area.is_default) {
        return false;
      }

      try {
        yield call(setAsDefaultArea, area._id);
        yield put({ type: `loadAreas` });
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};

export default model;
