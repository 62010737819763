import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';
import { stringify } from './querystring';

export function getCows(query) {
  const url = `${BASE_URL}/api/v1/cows?${stringify(query)}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function getPairedCows(query) {
  const url = `${BASE_URL}/api/v1/paired_cows?${stringify(query)}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function getVisibilityStatus() {
  const url = `${BASE_URL}/api/v1/cows/visibility_status`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function getDetailCow(id) {
  const url = `${BASE_URL}/api/v1/cows/${id}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function getAreaOfCow(id) {
  const url = `${BASE_URL}/api/v1/cows/${id}/area`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function setAreaOfCow(cowId, areaId) {
  const url = `${BASE_URL}/api/v1/cows/${cowId}/area`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `PUT`,
    body: JSON.stringify({
      area_id: areaId,
    }),
  };

  return getAndParseData(url, options);
}

export function getHistoryMapOfCow(id, timeFrame) {
  const url = `${BASE_URL}/api/v1/cows/${id}/history_map?time=${timeFrame}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function createCow(body) {
  const url = `${BASE_URL}/api/v1/cows`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `POST`,
    body: JSON.stringify(body),
  };

  return getAndParseData(url, options);
}

export function updateCow(id, body) {
  const url = `${BASE_URL}/api/v1/cows/${id}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `PATCH`,
    body: JSON.stringify(body),
  };

  return getAndParseData(url, options);
}
