import React from 'react';
import { Router, Route, Switch } from 'dva/router';
import dynamic from 'dva/dynamic';

import NotFoundPage from './pages/NotFound';
import PrivateRoute from './components/PrivateRoute';
import withApp from './components/AppWrapper';
import OAuth2Callback from './components/OAuth2Callback';
import SignOutPage from './pages/SignOut';

function RouterConfig({ history, app }) {
  const OverviewPage = dynamic({
    component: () => import(`./pages/Overview`),
  });

  const DashboardPage = dynamic({
    component: () => import(`./pages/Dashboard`),
  });

  const DeviceListPage = dynamic({
    app,
    component: () => import(`./pages/DeviceList`),
    models: () => [import(`./models/devices`)],
  });

  const DetailCowPage = dynamic({
    component: () => import(`./pages/DetailCow`),
  });

  const AddNewAreaPage = dynamic({
    component: () => import(`./pages/AddNewArea`),
  });

  const ListAreaPage = dynamic({
    component: () => import(`./pages/ListArea`),
  });

  const ListCowPage = dynamic({
    app,
    component: () => import(`./pages/ListCows`),
    models: () => [import(`./models/cows_management`)],
  });

  const CreateCowPage = dynamic({
    component: () => import(`./pages/CreateCow`),
  });

  const EditCowPage = dynamic({
    component: () => import(`./pages/EditCow`),
  });

  const ListUsersPage = dynamic({
    component: () => import(`./pages/UsersPage`),
  });

  const FarmProfilePage = dynamic({
    component: () => import(`./pages/FarmProfile`),
  });

  const NewUserPage = dynamic({
    component: () => import(`./pages/NewUser`),
  });

  const UserDetailPage = dynamic({
    component: () => import(`./pages/UserDetail`),
  });

  const DetailCounterStation = dynamic({
    component: () => import(`./pages/DetailCounterStation`),
  });

  const CounterStationsPage = dynamic({
    component: () => import(`./pages/CounterStations`),
  });

  const CreateCounterStatioPage = dynamic({
    component: () => import(`./pages/CreateCounterStation`),
  });

  const DownloadCenterPage = dynamic({
    component: () => import(`./pages/DownloadCenter`),
  });

  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path="/" component={withApp(DashboardPage)} />
        <PrivateRoute
          exact
          path="/tracking"
          component={withApp(OverviewPage)}
        />
        <PrivateRoute
          exact
          path="/devices"
          component={withApp(DeviceListPage)}
        />
        <PrivateRoute exact path="/cows" component={withApp(ListCowPage)} />
        <PrivateRoute
          exact
          path="/cows/new"
          component={withApp(CreateCowPage)}
        />
        <PrivateRoute
          exact
          path="/cows/:id/details"
          component={withApp(DetailCowPage)}
        />
        <PrivateRoute
          exact
          path="/cows/:id/edit"
          component={withApp(EditCowPage)}
        />
        <PrivateRoute exact path="/areas" component={withApp(ListAreaPage)} />
        <PrivateRoute
          exact
          path="/areas/new"
          component={withApp(AddNewAreaPage)}
        />
        <PrivateRoute exact path="/users" component={withApp(ListUsersPage)} />
        <PrivateRoute
          exact
          path="/profile"
          component={withApp(FarmProfilePage)}
        />
        <PrivateRoute
          exact
          path="/users/new"
          component={withApp(NewUserPage)}
        />
        <PrivateRoute
          exact
          path="/users/:id/details"
          component={withApp(UserDetailPage)}
        />
        <PrivateRoute
          exact
          path="/counter_stations"
          component={withApp(CounterStationsPage)}
        />
        <PrivateRoute
          exact
          path="/counter_stations/new"
          component={withApp(CreateCounterStatioPage)}
        />
        <PrivateRoute
          exact
          path="/counter_stations/:id/details"
          component={withApp(DetailCounterStation)}
        />
        <PrivateRoute
          exact
          path="/download_center"
          component={withApp(DownloadCenterPage)}
        />
        <Route exact path="/callback" component={OAuth2Callback} />
        <Route exact path="/signout" component={SignOutPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default RouterConfig;
