import React from 'react';
import { Route } from 'dva/router';
import PropTypes from 'prop-types';
import { isLoggedIn } from '../utils/auth';
import OAuth2Login from './OAuth2Login';

function PrivateRoute(props) {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? <Component {...props} /> : <OAuth2Login />
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
