import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';
import { stringify } from './querystring';

export function getHistoricalDataOf(cowId, sensor, time = `1day`) {
  const query = {
    time,
    limit: 50,
    sort_by: `_id`,
    sort_dir: `desc`,
  };

  const url = `${BASE_URL}/api/v1/cows/${cowId}/historical_data/${sensor}?${stringify(
    query
  )}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}
