import dva from 'dva';
import { createBrowserHistory } from 'history';
// import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
// import Leaflet from 'leaflet';

import './index.css';

import router from './router';
import appModel from './models/app';
import dashboardModel from './models/dashboard';
import realtimeModel from './models/realtime_tracking';
import detailCowModel from './models/detail_cow';
import historicalDataModel from './models/historical_data';
import predictedActivityStatsModel from './models/predicted_activity_stats';
import trackingModel from './models/tracking';
import listAreaModel from './models/list_area';
import waterLevelSensorsModel from './models/water_level_sensors';
import usersModel from './models/users';
import fodderModel from './models/fodder';
import userDataModel from './models/user_data';
import cowsRecommendationModel from './models/cows_recommendation';
import counterStationsModel from './models/counter_stations';
import detailCounterStationModel from './models/counter_station_detail';

// Leaflet.Icon.Default.imagePath =
//   '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/images/';

const app = dva({
  history: createBrowserHistory(),
});

app.router(router);
app.model(appModel);
app.model(dashboardModel);
app.model(realtimeModel);
app.model(detailCowModel);
app.model(historicalDataModel);
app.model(predictedActivityStatsModel);
app.model(trackingModel);
app.model(listAreaModel);
app.model(waterLevelSensorsModel);
app.model(usersModel);
app.model(fodderModel);
app.model(userDataModel);
app.model(cowsRecommendationModel);
app.model(counterStationsModel);
app.model(detailCounterStationModel);

app.start(`#root`);
