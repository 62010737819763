import {
  getDetailCounterStation,
  getCounterSessionStatByID,
} from '../utils/counter_stations_api';
import 'moment-timezone';
import moment from 'moment';
import { wsInstance } from '../websocket';

const model = {
  namespace: `counter_station_details`,
  state: {
    id: ``,
    data: {
      location: {
        type: `Point`,
        coordinates: [0, 0],
      },
    },

    stats: {
      top_context: `min`,
      total_sum: 0,
      data: [],
    },
  },

  reducers: {
    init(state, action) {
      return {
        ...state,
        id: action.id,
      };
    },

    fetched(state, action) {
      return {
        ...state,
        data: action.result,
      };
    },

    fetchStats(state, action) {
      return {
        ...state,
        stats: {
          ...state.stats,
          top_context: action.context,
        },
      };
    },

    fetchedStats(state, action) {
      return {
        ...state,
        stats: {
          ...state.stats,
          total_sum: action.result.total_sum,
          data: action.result.result,
        },
      };
    },

    realtimeCounting(state, action) {
      const stats = { ...state.stats };
      const message = action.data;

      console.log(message);

      if (message.id !== state.data.id) {
        return state;
      }

      if (stats.data.length > 0) {
        // update existing record if exists
        stats.data[0].timestamps[1] = new Date().toLocaleString();
        stats.data[0].count += message.data.count;
      } else {
        // otherwise push new data
        stats.data.push({
          timestamps: [
            new Date().toLocaleString(),
            new Date().toLocaleString(),
          ],
          count: message.data.count,
        });
      }

      stats.total_sum += message.data.count;

      return {
        ...state,
        stats: {
          ...stats,
        },
      };
    },
  },
  effects: {
    *fetch({ id }, { call, put }) {
      try {
        const result = yield call(getDetailCounterStation, id);
        yield put({ type: `fetched`, result });
      } catch (ex) {
        console.error(ex);
      }
    },

    *fetchStats({ id, context }, { call, put }) {
      try {
        const timezone = moment.tz.guess();
        const result = yield call(
          getCounterSessionStatByID,
          id,
          context,
          timezone
        );

        yield put({ type: `fetchedStats`, result });
      } catch (ex) {
        console.error(ex);
      }
    },
  },

  subscriptions: {
    realtimeCounting({ dispatch }) {
      wsInstance.onEvent(`smarternak/camera/counting/tx`, (data) => {
        try {
          const parsed = JSON.parse(data);
          dispatch({
            type: `counter_station_details/realtimeCounting`,
            data: parsed,
          });
        } catch (ex) {
          console.error(ex);
        }
      });

      return () => {
        wsInstance.offEvent(`smarternak/camera/counting/tx`);
      };
    },
  },
};

export default model;
