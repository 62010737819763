import React from 'react';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'dva/router';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import MainMenuList from './MainMenuList';
import menus from '../menus';

import logo from '../images/smarternak-logo.png';

const drawerWidth = 240;

const style = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: `white`,
  },

  logo: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    marginLeft: theme.spacing(),
  },

  hide: {
    display: 'none',
  },

  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  drawer: {
    minHeight: `100vh`,
    height: `100vh`,
  },
});

const useStyles = makeStyles(style);

function AppBarWithDrawer(props) {
  const classes = useStyles();

  const {
    isDrawerOpened,

    onDrawerOpened,
    onDrawerClosed,
  } = props;

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        color="default"
        className={classNames(
          classes.appBar,
          isDrawerOpened && classes.appBarShift
        )}
      >
        <Toolbar disableGutters={!isDrawerOpened}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onDrawerOpened}
            className={classNames(
              classes.menuButton,
              isDrawerOpened && classes.hide
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logo}>
            <Link to="/">
              <img src={logo} width={150} alt="SMARTernak logo" />
            </Link>
          </div>

          <div>
            <Link to="/signout">
              <IconButton>
                <ExitToAppIcon />
              </IconButton>
            </Link>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !isDrawerOpened && classes.drawerPaperClose
          ),
        }}
        open={isDrawerOpened}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={onDrawerClosed}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />

        <MainMenuList menus={menus} />
      </Drawer>
    </div>
  );
}

AppBarWithDrawer.propTypes = {
  isDrawerOpened: PropTypes.bool,
  onDrawerOpened: PropTypes.func,
  onDrawerClosed: PropTypes.func,
};

export default AppBarWithDrawer;
