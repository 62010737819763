import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';
import { stringify } from './querystring';

export function getChartData(id, labels = '') {
  let url = `${BASE_URL}/api/v1/predicted_activities/daily_stats/${id}`;
  if (labels) {
    url += `?${stringify({ labels })}`;
  }

  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function getLatestActivity(id, labels) {
  let url = `${BASE_URL}/api/v1/predicted_activities/latest/${id}`;
  if (labels) {
    url += `?${stringify({ labels })}`;
  }

  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function getAcvitiyDuration(
  id,
  timeWindow = `1hour`,
  alternateSeries = ``,
  labels = ''
) {
  const query = stringify({
    labels,
    alternateSeries,
    time: timeWindow,
  });

  const url = `${BASE_URL}/api/v1/cows/${id}/activity_durations?${query}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}
