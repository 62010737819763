import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';
import { stringify } from './querystring';

export function getUsers(query = {}) {
  const url = `${BASE_URL}/api/v1/users?${stringify(query)}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function createUsers(body) {
  const url = `${BASE_URL}/api/v1/users`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `POST`,
    body: JSON.stringify(body),
  };

  return getAndParseData(url, options);
}

export function getOneUser(id) {
  const url = `${BASE_URL}/api/v1/users/${id}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}
