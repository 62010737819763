import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';

export function getOwnerDetail() {
  const url = `${BASE_URL}/api/v1/owners/me`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}
