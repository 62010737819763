import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';

export function getAvailableFodderCount() {
  const url = `${BASE_URL}/api/v1/fodder/available_count`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}
