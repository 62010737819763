import React from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import CropLandscapeIcon from '@material-ui/icons/CropLandscape';
import PetsIcon from '@material-ui/icons/Pets';
import PeopleIcon from '@material-ui/icons/People';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import VideocamIcon from '@material-ui/icons/Videocam';

const menus = [
  {
    text: `Dashboard`,
    linkTo: `/`,
    icon: <DashboardIcon />,
  },
  {
    text: `Tracking`,
    linkTo: `/tracking`,
    icon: <LocationOnIcon />,
  },
  {
    text: `Animal Management`,
    linkTo: `/cows`,
    icon: <PetsIcon />,
  },
  {
    text: `Devices`,
    linkTo: `/devices`,
    icon: <DeveloperBoardIcon />,
  },
  {
    text: `Areas`,
    linkTo: `/areas`,
    icon: <CropLandscapeIcon />,
  },
  // {
  //   text: `Counter Stations`,
  //   linkTo: `/counter_stations`,
  //   icon: <VideocamIcon />,
  // },
  {
    text: `Download Center`,
    linkTo: `/download_center`,
    icon: <CloudDownloadIcon />,
  },
  {
    text: `Farm Profile`,
    linkTo: `/profile`,
    icon: <PeopleIcon />,
  },
];

export default menus;
