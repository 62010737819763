import { getCounterStations } from '../utils/counter_stations_api';

const model = {
  namespace: `counter_stations`,
  state: {
    data: [],
    metadata: {
      total_count: 0,
      offset: 0,
      limit: 25,
    },
  },

  reducers: {
    fetched(state, actions) {
      return {
        ...state,
        data: actions.result.data.map((datum) => {
          const newData = { ...datum };
          newData.deployed_at = new Date(newData.deployed_at).toLocaleString();
          newData.created_at = new Date(newData.created_at).toLocaleString();
          newData.updated_at = new Date(newData.updated_at).toLocaleString();

          return newData;
        }),

        metadata: {
          offset: actions.result.offset,
          limit: actions.result.limit,
          total_count: actions.result.total_count,
        },
      };
    },
  },
  effects: {
    *fetch({ query }, { call, put }) {
      try {
        const result = yield call(getCounterStations, query);
        yield put({ type: `fetched`, result });
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};

export default model;
