import React, { useState, useEffect } from 'react';
import { connect } from 'dva';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AppBarWithDrawer from './AppBarWithDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: `hidden`,
    position: `relative`,
    display: `flex`,
  },

  content: {
    flexGrow: 1,
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
}));

export function AppWrapper(props) {
  const { snackbarMessage, snackbarOpened, closeSnackbar, loadFarmData } =
    props;
  const classes = useStyles();
  const [isDrawerOpened, setDrawerState] = useState(false);
  useEffect(() => {
    loadFarmData();
  }, [loadFarmData]);

  return (
    <div className={classes.root}>
      <AppBarWithDrawer
        isDrawerOpened={isDrawerOpened}
        pageTitle={`SMARTernak`}
        onDrawerOpened={() => setDrawerState(true)}
        onDrawerClosed={() => setDrawerState(false)}
      />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpened}
        onClose={() => {
          closeSnackbar();
        }}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
      />
    </div>
  );
}

AppWrapper.propTypes = {
  snackbarOpened: PropTypes.bool,
  closeSnackbar: PropTypes.func,
  snackbarMessage: PropTypes.string,
  loadFarmData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  snackbarOpened: state.app.snackbar_open,
  snackbarMessage: state.app.snackbar_message,
});

const mapDispatchToProps = (dispatch) => ({
  closeSnackbar: () => dispatch({ type: `app/hideSnackbar` }),
  loadFarmData: () => dispatch({ type: `app/getOwnerData` }),
});

const DecoratedApp = connect(mapStateToProps, mapDispatchToProps)(AppWrapper);

export default function withApp(ChildComponent) {
  return function DecoratedWithApp(props) {
    return (
      <DecoratedApp>
        <ChildComponent {...props} />
      </DecoratedApp>
    );
  };
}
