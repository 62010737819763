const model = {
  attentions: [
    {
      linkTo: `/tracking?filter=1&highlight=lame&show=lame`,
      label: `Lame`,
      value: `0`,
    },
    {
      linkTo: `/tracking?filter=1&highlight=sick&show=sick`,
      label: `Sick`,
      value: `0`,
    },
    {
      linkTo: `/tracking?filter=1&highlight=unseen&show=unseen`,
      label: `Unseen`,
      value: `0`,
    },
    {
      linkTo: `/tracking?filter=1&highlight=watchlist&show=watchlist`,
      label: `Watch List`,
      value: `0`,
    },
  ],

  performance: [
    // {
    //   linkTo: `/tracking?filter=1&highlight=in_heat&show=in_heat`,
    //   label: ``,
    //   value: `0`,
    // },
    // {
    //   linkTo: `/tracking?filter=1&highlight=calving&show=calving`,
    //   label: ``,
    //   value: `0`,
    // },
    {
      linkTo: `/tracking?filter=1&highlight=weight_gain&show=weight_gain`,
      label: ``,
      value: `0`,
    },
    {
      linkTo: `/tracking?filter=1&highlight=active&show=active`,
      label: ``,
      value: `0`,
    },
  ],
};

export default model;
