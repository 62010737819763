import { BASE_URL, defaultHeaders, getAndParseData, withToken } from './api';
import { stringify } from './querystring';

export function getCounterStations(query = {}) {
  const url = `${BASE_URL}/api/v1/counter_stations?${stringify(query)}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function getDetailCounterStation(id) {
  const url = `${BASE_URL}/api/v1/counter_stations/${id}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function getCounterSessionStatByID(id, context, timezone) {
  const qs = {
    top_context: context,
    tz: timezone,
  };
  const url = `${BASE_URL}/api/v1/counter_stations/${id}/stats?${stringify(
    qs
  )}`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `GET`,
  };

  return getAndParseData(url, options);
}

export function createCounterStation(body) {
  const url = `${BASE_URL}/api/v1/counter_stations`;
  const headers = withToken({
    ...defaultHeaders,
  });

  const options = {
    headers,
    method: `POST`,
    body: JSON.stringify(body),
  };

  return getAndParseData(url, options);
}
