import { getToken } from './auth';
import config from '../config';

export const BASE_URL = config.api.baseUrl;
export const defaultHeaders = { ...config.api.defaultHeaders };

export function withToken(headers) {
  const token = getToken();
  if (token) {
    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return headers;
}

export function getAndParseData(url, options) {
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      if (
        response.headers.has('content-type') &&
        !response.headers.get('content-type').includes('application/json')
      ) {
        return response.text().then((text) => {
          return {
            skipCheck: true,
            headerPresent: true,
            data: text,
            header: response.headers,
          };
        });
      }

      return response.json();
    })
    .then((response) => {
      if (response.skipCheck) {
        return response;
      }

      if (response.meta) {
        if (response.meta.status >= 400) {
          throw new Error(response.meta.message);
        }
      }

      if (response.data) {
        return response.data;
      }

      return response;
    });
}
