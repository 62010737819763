import {
  getChartData,
  getLatestActivity,
  getAcvitiyDuration,
} from '../utils/predicted_activity_api';

const model = {
  namespace: `predicted_activity_stats`,
  state: {
    today_data: [],
    latest_activity: `unknown`,
    active_cattle_id: ``,
    daily_duration_data: [],
    alternate_series_data: [],
  },

  reducers: {
    loadedCattleID(state, action) {
      return {
        ...state,
        active_cattle_id: action.id,
      };
    },

    loadedTodayData(state, action) {
      return {
        ...state,
        today_data: action.result,
      };
    },

    loadedLatestActivity(state, action) {
      return {
        ...state,
        latest_activity: action.result.activity,
      };
    },

    loadedDailyDurationData(state, action) {
      const grouped = {};
      action.data.durations.forEach((datum) => {
        if (typeof grouped[datum.activity] === `undefined`) {
          grouped[datum.activity] = datum.duration;
        } else {
          grouped[datum.activity] += datum.duration;
        }
      });

      const result = Object.keys(grouped).map((key) => ({
        activity: key,
        duration: grouped[key],
      }));

      return {
        ...state,
        daily_duration_data: result,
      };
    },

    fetchedActivityDurationsDialog(state, action) {
      return {
        ...state,
        activity_durations_dialog_data: action.data.durations.map((datum) => {
          const newDatum = {
            ...datum,
            start: new Date(datum.start),
            end: new Date(datum.end),
          };

          return newDatum;
        }),

        activity_duration_time: action.time,
        alternate_series_data: action.data.alternate_series.map((data) => ({
          time: new Date(data.time),
          value: data.value,
        })),
      };
    },

    newTelemetryData(state, action) {
      const telemetryData = { ...action.preprocessedData };
      if (
        (telemetryData.motion_prediction || telemetryData.motion_type === 0) &&
        state.active_cattle_id === telemetryData.cow_object_id
      ) {
        const newState = { ...state };
        const newTodayData = newState.today_data.slice();

        newState.latest_activity = telemetryData.motion_prediction || `idle`;

        for (let i = 0; i < newTodayData.length; i++) {
          const current = newTodayData[i];
          if (current.activity === telemetryData.motion_prediction) {
            newTodayData[i] = {
              ...newTodayData[i],
              count: newTodayData[i].count + 1,
            };
          } else if (current.activity === `idle`) {
            newTodayData[i] = {
              ...newTodayData[i],
              count: newTodayData[i].count + 1,
            };
          }
        }

        newState.today_data = newTodayData;
        return { ...newState };
      }

      return state;
    },
  },

  effects: {
    *loadTodayData(action, { call, put }) {
      const { id, labels } = action;
      yield put({ type: `loadedCattleID`, id });

      const result = yield call(getChartData, id, labels.join(','));

      yield put({ type: `loadedTodayData`, result });
    },

    *loadLatestActivity(action, { call, put }) {
      const { id, labels = [] } = action;
      yield put({ type: `loadedCattleID`, id });

      const result = yield call(getLatestActivity, id, labels.join(','));

      yield put({ type: `loadedLatestActivity`, result });
    },

    *loadDailyDurationData(action, { call, put }) {
      const { id, labels } = action;
      try {
        const result = yield call(
          getAcvitiyDuration,
          id,
          `1hour`,
          '',
          labels.join(',')
        );
        yield put({ type: `loadedDailyDurationData`, data: result });
      } catch (ex) {
        console.error(ex);
      }
    },

    *fetchActivityDurationsDialog(action, { call, put }) {
      const { id, timeFrame, alternateSeries, labels = [] } = action;

      try {
        const result = yield call(
          getAcvitiyDuration,
          id,
          timeFrame,
          alternateSeries,
          labels.join(',')
        );
        yield put({
          type: `fetchedActivityDurationsDialog`,
          time: timeFrame,
          data: result,
        });
      } catch (ex) {
        console.error(ex);
      }
    },
  },
};

export default model;
